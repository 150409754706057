//
// Styles
//

// Import variables
@import "./variables.scss";

// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Global CSS
@import "./global.scss";

// Components
@import "./components/buttons.scss";
@import "./components/navbar.scss";

// Sections
@import "./sections/masthead.scss";


.team-shadowkite {
  background: url("https://api.cryptor.at/original/rat/597");
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: contain;
}

.team-insanity {
  background: url("https://api.cryptor.at/nightmares/rat/800,1597,1982,5133_173,5244_206");
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: contain;
}